import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../styles/EasyProcess.css';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';
import step4 from '../assets/step4.png';

const steps = [
  { img: step1, title: '01 YOU ASK', description: 'You ask us your requirements.' },
  { img: step3, title: '02 WE DESIGN', description: 'We design your vision into reality.' },
  { img: step2, title: '03 WE PROCEED', description: 'We proceed with the initial setup.' },
  { img: step4, title: '04 YOU GET', description: 'You get the final product.' },
];

const EasyProcess = () => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const section = document.getElementById('easy-process');
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(section);
    return () => observer.disconnect();
  }, []);

  return (
    <div id="easy-process" className="easy-process-container">
      <h2>How to Start</h2>
      <h3>Easy Process</h3>
      <p>We specialize in helping you build a team of expert developers, testers, and leaders.</p>
      <div className="steps">
        {steps.map((step, index) => (
          <motion.div
            className="step"
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 1, delay: index * 0.5 }}
          >
            <img src={step.img} alt={step.title} loading="lazy" />
            <h4>{step.title}</h4>
            <p>{step.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default EasyProcess;
