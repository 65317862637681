import React from 'react';
import { motion } from 'framer-motion';
import heroImage from '../assets/index.png';
import '../styles/MainSection.css';

const MainSection = () => (
  <div id="about" className="main-container">
    <div className="animated-background"></div>
    <div className="image-container">
      <img src={heroImage} alt="Team working on a project" className="hero-image" loading="lazy" />
      <div className="content-container">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="main-title"
        >
          Great Product Is Built By Great Teams
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="main-subtitle"
        >
          We work to help startups and industry leaders build their prototypes to life.
        </motion.p>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="contact-button"
          onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
        >
          Contact Us
        </motion.button>
      </div>
    </div>
  </div>
);

export default MainSection;
