import React, { useEffect, useRef } from 'react';
import '../styles/Services.css';
import ai from '../assets/AIML.gif';
import app from '../assets/App development .gif';
import block from '../assets/bitcoin-lock.gif';
import data from '../assets/database.gif';
import iot from '../assets/Iot.gif';
import logo from '../assets/logo-design.gif';
import software from '../assets/Software .gif';
import ui from '../assets/ux-design.gif';
import web from '../assets/web-developer.gif';

const services = [
  { 
    img: web, 
    title: 'Web Development', 
    description: 'Creating responsive and dynamic websites using HTML, CSS, JavaScript, and React. Our web development ensures optimal performance and seamless user experiences. Perfect for businesses looking to establish a strong online presence.' 
  },
  { 
    img: software, 
    title: 'Software Development', 
    description: 'Building robust and scalable software solutions with Java, Python, and .NET. Our software development services drive your business forward by meeting evolving demands. Ideal for enterprises seeking customized software applications.' 
  },
  { 
    img: app, 
    title: 'App Development', 
    description: 'Developing high-performing mobile applications for iOS and Android using Swift, Kotlin, and Flutter. Our apps enhance your digital presence with intuitive design and functionality. Suitable for businesses aiming to reach a wider audience.' 
  },
  { 
    img: ui, 
    title: 'UI/UX Design', 
    description: 'Designing intuitive and user-friendly interfaces with Figma, Sketch, and Adobe XD. Our UI/UX design services elevate user engagement and satisfaction. Essential for brands looking to improve their digital user experience.' 
  },
  { 
    img: logo, 
    title: 'Logo Design', 
    description: 'Crafting unique and memorable logos with Illustrator and Photoshop. Our logo designs capture and convey your brand identity effectively. Great for businesses wanting to make a lasting visual impact.' 
  },
  { 
    img: ai, 
    title: 'Data Science & ML', 
    description: 'Leveraging Python, R, and TensorFlow to extract valuable insights from data. Our data science and machine learning services drive innovation and decision-making. Perfect for companies looking to harness the power of their data.' 
  },
  { 
    img: iot, 
    title: 'IoT', 
    description: 'Integrating smart IoT solutions with Arduino, Raspberry Pi, and MQTT. Our IoT services connect and automate your devices for improved efficiency. Ideal for businesses aiming to enhance operational performance.' 
  },
  { 
    img: block, 
    title: 'Blockchain', 
    description: 'Implementing secure and efficient blockchain technology using Ethereum, Hyperledger, and Solidity. Our blockchain services streamline and safeguard your business transactions. Suitable for enterprises seeking advanced digital ledger solutions.' 
  },
  { 
    img: data, 
    title: 'Database Management', 
    description: 'Managing and optimizing databases with MySQL, Firebase, MongoDB, and Oracle. Our database management ensures data integrity, accessibility, and performance. Essential for organizations needing reliable data solutions.' 
  },
];

const Services = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    const scrollNext = () => {
      const cards = slider.children;
      const firstCard = cards[0];

      // Move the first card to the end
      slider.appendChild(firstCard);

      // Scroll to the next card (now the first card)
      slider.scrollLeft = 0;

      // Apply zoom class to the middle card
      Array.from(cards).forEach((card) => {
        card.classList.remove('zoom');
      });

      const middleCardIndex = Math.floor(cards.length / 2);
      if (cards[middleCardIndex]) {
        cards[middleCardIndex].classList.add('zoom');
      }
    };

    const interval = setInterval(scrollNext, 3000); // Adjust interval for automatic scrolling

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="services" className="services-container">
      <h2 className="services-title">Services We Offer</h2>
      <div className="service-slider" ref={sliderRef}>
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.img} alt={service.title} className="service-img" loading="lazy" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
