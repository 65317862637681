import React, { useState, useEffect } from 'react';
import '../styles/Contact.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';  // Import emailjs

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBP9mewwpv-EsYEmqh0cZWQX37hcmVIru0",
  authDomain: "nebsia-cb1f4.firebaseapp.com",
  projectId: "nebsia-cb1f4",
  storageBucket: "nebsia-cb1f4",
  messagingSenderId: "794829689740",
  appId: "1:794829689740:web:0dab5dbb858c0bb1513ca9",
  measurementId: "G-Y7G6PPVMPT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    details: '',
    service: 'Product Design',
  });

  useEffect(() => {
    const handleScroll = () => {
      const formSection = document.querySelector('.form-section');
      const contactInfo = document.querySelector('.contact-info');

      const formSectionPosition = formSection.getBoundingClientRect().top;
      const contactInfoPosition = contactInfo.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.2; // Adjust trigger point as needed

      if (formSectionPosition < screenPosition) {
        formSection.classList.add('in-view');
      }

      if (contactInfoPosition < screenPosition) {
        contactInfo.classList.add('in-view');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const phoneNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (phoneNumber.length <= 10) {
        setFormData({ ...formData, [name]: phoneNumber });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    if (formData.phone.length !== 10) {
      toast.error('Please enter a valid 10-digit phone number.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    if (!formData.email) {
      toast.error('Please enter a valid email address.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      // Add form data to Firestore
      await addDoc(collection(db, 'contacts'), formData);

      // Send email notification to the user
      emailjs.send('service_7x5rb4y', 'template_yavdgix', formData, '06j5R76_WyUpTSi8K')
        .then(() => {
          toast.success('Your message has been submitted successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => {
          console.error('Error sending email: ', error);
          toast.error('An error occurred while sending your email. Please try again.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });

      // Send an email with the form data to your email address
      emailjs.send('service_7x5rb4y', 'template_9sfseq7', formData, '06j5R76_WyUpTSi8K')
        .then(() => {
          console.log('Email sent to admin');
        })
        .catch((error) => {
          console.error('Error sending email to admin: ', error);
        });

      // Reset form data
      setFormData({ name: '', email: '', phone: '', details: '', service: 'Product Design' });
    } catch (error) {
      toast.error('An error occurred while submitting your message. Please try again.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div id="contact" className="contact-container">
      <ToastContainer />
      <h2 className="contact-heading">Contact us!</h2>
      <p className="contact-subheading">What can we do for you?</p>
      <div className="contact-content">
        <div className="form-section">
          <div className="service-options">
            <button
              className={formData.service === 'Product Design' ? 'service-button active' : 'service-button'}
              onClick={() => setFormData({ ...formData, service: 'Product Design' })}
            >
              Product Design
            </button>
            <button
              className={formData.service === 'Website Development' ? 'service-button active' : 'service-button'}
              onClick={() => setFormData({ ...formData, service: 'Website Development' })}
            >
              Website Development
            </button>
            <button
              className={formData.service === 'Mobile Apps Development' ? 'service-button active' : 'service-button'}
              onClick={() => setFormData({ ...formData, service: 'Mobile Apps Development' })}
            >
              Mobile Apps Development
            </button>
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form__group">
              <input
                type="text"
                className="form__field"
                placeholder="Name"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="name" className="form__label">Name</label>
            </div>
            <div className="form__group">
              <input
                type="email"
                className="form__field"
                placeholder="Email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="email" className="form__label">Email</label>
            </div>
            <div className="form__group">
              <input
                type="text"
                className="form__field"
                placeholder="Phone Number"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
                pattern="\d{10}"  // HTML5 pattern attribute for validation
                title="Please enter a valid 10-digit phone number"
              />
              <label htmlFor="phone" className="form__label">Phone Number</label>
            </div>
            <div className="form__group">
              <textarea
                className="form__field"
                placeholder="Project details"
                name="details"
                id="project-details"
                value={formData.details}
                onChange={handleInputChange}
                required
              ></textarea>
              <label htmlFor="project-details" className="form__label">Project details</label>
            </div>
            <button type="submit" className="submit-button">
              <span>→</span>
            </button>
          </form>
        </div>
        <div className="contact-info">
          <p>Phone <br/><a href="tel:+91 8300076220">8300076220</a></p>
          <p>Email <br/><a href="mailto:info@nebisa.com">info@nebisa.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
