// src/components/StarGlitter.js

import React, { useState, useEffect } from 'react';
import '../styles/starGlitter.css';

const StarGlitter = () => {
  const [particles, setParticles] = useState([]);

  const handleMouseMove = (e) => {
    const newParticle = {
      id: Math.random(),
      x: e.clientX,
      y: e.clientY,
      size: Math.random() * 15 + 10,
      opacity: Math.random() * 0.7 + 0.3,
      type: Math.random() > 0.5 ? 'star' : 'dot', // Randomly decide between star or dot
    };

    setParticles((prevParticles) => [...prevParticles, newParticle]);

    setTimeout(() => {
      setParticles((prevParticles) => prevParticles.filter((particle) => particle.id !== newParticle.id));
    }, 1000); // Adjust time for the trail effect
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="star-container">
      {particles.map((particle) => (
        <div
          key={particle.id}
          className={particle.type}
          style={{
            left: particle.x,
            top: particle.y,
            width: particle.size,
            height: particle.size,
            opacity: particle.opacity,
          }}
        />
      ))}
    </div>
  );
};

export default StarGlitter;
