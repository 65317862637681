import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import logo from '../assets/logo.png'; 

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      return; 
    }

    const topLine_2 = document.getElementById("top-line-2");
    const middleLine_2 = document.getElementById("middle-line-2");
    const bottomLine_2 = document.getElementById("bottom-line-2");
    let currentFrame_2 = 0;

    const segmentDuration_2 = 25;
    const menuDisappearDurationInFrames_2 = segmentDuration_2;
    const crossAppearDurationInFrames_2 = Math.round(segmentDuration_2 * 0.35);
    const crossDisappearDurationInFrames_2 = Math.round(segmentDuration_2 * 0.6);
    const menuAppearDurationInFrames_2 = segmentDuration_2;
    const lineDisappearDelay_2 = segmentDuration_2 * 0.2;
    const lineAppearDelay_2 = lineDisappearDelay_2;
    const lineDisappearDurationInFrames_2 = segmentDuration_2 - lineDisappearDelay_2 * 2;
    const lineAppearDurationInFrames_2 = lineDisappearDurationInFrames_2;

  
    function linear(start, end, duration, current) {
      return start + (end - start) * (current / duration);
    }

    function easeInQuad(start, end, duration, current) {
      current /= duration;
      return (end - start) * current * current + start;
    }

    function easeInBack(start, end, duration, current) {
      const s = 1.70158;
      current /= duration;
      return (end - start) * (current) * current * ((s + 1) * current - s) + start;
    }

    function easeOutBack(start, end, duration, current) {
      const s = 1.70158;
      current = current / duration - 1;
      return (end - start) * (current * current * ((s + 1) * current + s) + 1) + start;
    }

    function easeInExpo(start, end, duration, current) {
      return start + (end - start) * (Math.pow(2, 10 * (current / duration - 1)));
    }

    function menuDisappearAnimation_2() {
      currentFrame_2++;
      if (currentFrame_2 <= menuDisappearDurationInFrames_2) {
        window.requestAnimationFrame(() => {
          if (currentFrame_2 <= lineDisappearDurationInFrames_2) {
            const topLeftX_2 = easeInBack(30, 130, lineDisappearDurationInFrames_2, currentFrame_2);
            const topRightX_2 = easeInBack(70, 170, lineDisappearDurationInFrames_2, currentFrame_2);
            topLine_2.setAttribute("d", `M${topLeftX_2},37 L${topRightX_2},37 Z`);
            const topLineOpacity_2 = linear(1, 0, lineDisappearDurationInFrames_2 * 0.85, currentFrame_2);
            topLine_2.style.opacity = topLineOpacity_2;
          }

          if (currentFrame_2 > lineDisappearDelay_2 && currentFrame_2 <= lineDisappearDurationInFrames_2 + lineDisappearDelay_2) {
            const middleLeftX_2 = easeInBack(30, 130, lineDisappearDurationInFrames_2, currentFrame_2 - lineDisappearDelay_2);
            const middleRightX_2 = easeInBack(70, 170, lineDisappearDurationInFrames_2, currentFrame_2 - lineDisappearDelay_2);
            middleLine_2.setAttribute("d", `M${middleLeftX_2},50 L${middleRightX_2},50 Z`);
            const middleLineOpacity_2 = linear(1, 0, lineDisappearDurationInFrames_2 * 0.85, currentFrame_2 - lineDisappearDelay_2);
            middleLine_2.style.opacity = middleLineOpacity_2;
          }

          if (currentFrame_2 > lineDisappearDelay_2 * 2) {
            const bottomLeftX_2 = easeInBack(30, 130, lineDisappearDurationInFrames_2, currentFrame_2 - lineDisappearDelay_2 * 2);
            const bottomRightX_2 = easeInBack(70, 170, lineDisappearDurationInFrames_2, currentFrame_2 - lineDisappearDelay_2 * 2);
            bottomLine_2.setAttribute("d", `M${bottomLeftX_2},63 L${bottomRightX_2},63 Z`);
            const bottomLineOpacity_2 = linear(1, 0, lineDisappearDurationInFrames_2 * 0.85, currentFrame_2 - lineDisappearDelay_2 * 2);
            bottomLine_2.style.opacity = bottomLineOpacity_2;
          }

          menuDisappearAnimation_2();
        });
      } else {
        currentFrame_2 = 0;
        crossAppearAnimation_2();
      }
    }

    function crossAppearAnimation_2() {
      currentFrame_2++;
      if (currentFrame_2 <= crossAppearDurationInFrames_2) {
        window.requestAnimationFrame(() => {
          const topLeftX_2 = easeInQuad(-30, 35, crossAppearDurationInFrames_2, currentFrame_2);
          const topLeftY_2 = easeInQuad(-30, 35, crossAppearDurationInFrames_2, currentFrame_2);
          const bottomRightX_2 = easeInQuad(0, 65, crossAppearDurationInFrames_2, currentFrame_2);
          const bottomRightY_2 = easeInQuad(0, 65, crossAppearDurationInFrames_2, currentFrame_2);
          topLine_2.setAttribute("d", `M${topLeftX_2},${topLeftY_2} L${bottomRightX_2},${bottomRightY_2}`);
          const topLineOpacity_2 = easeInExpo(0, 1, crossAppearDurationInFrames_2, currentFrame_2);
          topLine_2.style.opacity = topLineOpacity_2;

          const bottomLeftX_2 = easeInQuad(-30, 35, crossAppearDurationInFrames_2, currentFrame_2);
          const bottomLeftY_2 = easeInQuad(130, 65, crossAppearDurationInFrames_2, currentFrame_2);
          const topRightX_2 = easeInQuad(0, 65, crossAppearDurationInFrames_2, currentFrame_2);
          const topRightY_2 = easeInQuad(100, 35, crossAppearDurationInFrames_2, currentFrame_2);
          bottomLine_2.setAttribute("d", `M${bottomLeftX_2},${bottomLeftY_2} L${topRightX_2},${topRightY_2}`);
          const bottomLineOpacity_2 = easeInExpo(0, 1, crossAppearDurationInFrames_2, currentFrame_2);
          bottomLine_2.style.opacity = bottomLineOpacity_2;

          crossAppearAnimation_2();
        });
      } else {
        currentFrame_2 = 0;
      }
    }

    function crossDisappearAnimation_2() {
      currentFrame_2++;
      if (currentFrame_2 <= crossDisappearDurationInFrames_2) {
        window.requestAnimationFrame(() => {
          const topLeftX_2 = easeInBack(35, -30, crossDisappearDurationInFrames_2, currentFrame_2);
          const topLeftY_2 = easeInBack(35, -30, crossDisappearDurationInFrames_2, currentFrame_2);
          const bottomRightX_2 = easeInBack(65, 0, crossDisappearDurationInFrames_2, currentFrame_2);
          const bottomRightY_2 = easeInBack(65, 0, crossDisappearDurationInFrames_2, currentFrame_2);
          topLine_2.setAttribute("d", `M${topLeftX_2},${topLeftY_2} L${bottomRightX_2},${bottomRightY_2}`);
          const topLineOpacity_2 = linear(1, 0, crossDisappearDurationInFrames_2, currentFrame_2);
          topLine_2.style.opacity = topLineOpacity_2;

          const bottomLeftX_2 = easeInBack(35, -30, crossDisappearDurationInFrames_2, currentFrame_2);
          const bottomLeftY_2 = easeInBack(65, 130, crossDisappearDurationInFrames_2, currentFrame_2);
          const topRightX_2 = easeInBack(65, 0, crossDisappearDurationInFrames_2, currentFrame_2);
          const topRightY_2 = easeInBack(35, 100, crossDisappearDurationInFrames_2, currentFrame_2);
          bottomLine_2.setAttribute("d", `M${bottomLeftX_2},${bottomLeftY_2} L${topRightX_2},${topRightY_2}`);
          const bottomLineOpacity_2 = linear(1, 0, crossDisappearDurationInFrames_2, currentFrame_2);
          bottomLine_2.style.opacity = bottomLineOpacity_2;

          crossDisappearAnimation_2();
        });
      } else {
        currentFrame_2 = 0;
        menuAppearAnimation_2();
      }
    }

    function menuAppearAnimation_2() {
      currentFrame_2++;
      if (currentFrame_2 <= menuAppearDurationInFrames_2) {
        window.requestAnimationFrame(() => {
          if (currentFrame_2 <= lineAppearDurationInFrames_2) {
            const topLeftX_2 = easeOutBack(130, 30, lineAppearDurationInFrames_2, currentFrame_2);
            const topRightX_2 = easeOutBack(170, 70, lineAppearDurationInFrames_2, currentFrame_2);
            topLine_2.setAttribute("d", `M${topLeftX_2},37 L${topRightX_2},37 Z`);
            const topLineOpacity_2 = linear(-2, 1, lineAppearDurationInFrames_2, currentFrame_2);
            topLine_2.style.opacity = topLineOpacity_2;
          }

          if (currentFrame_2 > lineAppearDelay_2 && currentFrame_2 <= lineAppearDurationInFrames_2 + lineAppearDelay_2) {
            const middleLeftX_2 = easeOutBack(130, 30, lineAppearDurationInFrames_2, currentFrame_2 - lineAppearDelay_2);
            const middleRightX_2 = easeOutBack(170, 70, lineAppearDurationInFrames_2, currentFrame_2 - lineAppearDelay_2);
            middleLine_2.setAttribute("d", `M${middleLeftX_2},50 L${middleRightX_2},50 Z`);
            const middleLineOpacity_2 = linear(-2, 1, lineAppearDurationInFrames_2, currentFrame_2 - lineAppearDelay_2);
            middleLine_2.style.opacity = middleLineOpacity_2;
          }

          if (currentFrame_2 > lineAppearDelay_2 * 2) {
            const bottomLeftX_2 = easeOutBack(130, 30, lineAppearDurationInFrames_2, currentFrame_2 - lineAppearDelay_2 * 2);
            const bottomRightX_2 = easeOutBack(170, 70, lineAppearDurationInFrames_2, currentFrame_2 - lineAppearDelay_2 * 2);
            bottomLine_2.setAttribute("d", `M${bottomLeftX_2},63 L${bottomRightX_2},63 Z`);
            const bottomLineOpacity_2 = linear(-2, 1, lineAppearDurationInFrames_2, currentFrame_2 - lineAppearDelay_2 * 2);
            bottomLine_2.style.opacity = bottomLineOpacity_2;
          }

          menuAppearAnimation_2();
        });
      } else {
        currentFrame_2 = 0;
      }
    }

    window.openMenuAnimation_2 = menuDisappearAnimation_2;
    window.closeMenuAnimation_2 = crossDisappearAnimation_2;

  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (window.innerWidth <= 768 && !sidebarOpen) {
      window.openMenuAnimation_2();
    } else if (window.innerWidth <= 768) {
      window.closeMenuAnimation_2();
    }
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    if (window.innerWidth <= 768) {
      window.closeMenuAnimation_2();
    }
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      closeSidebar();
    } else {
      console.error(`Element with id ${sectionId} not found.`);
    }
  };

  return (
    <header className="header-container">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <nav className={`nav-links ${sidebarOpen ? 'open' : ''}`}>
        <a href="#about" onClick={() => scrollToSection('about')}>About us</a>
        <a href="#services" onClick={() => scrollToSection('services')}>Services</a>
        <a href="#easy-process" onClick={() => scrollToSection('easy-process')}>Use Cases</a>
        <a href="#blog" onClick={() => scrollToSection('tech-stack')}>Tech Stack</a>
        <button className="contact-button" onClick={() => scrollToSection('contact')}>Contact Us</button>
      </nav>
      <div id="b2" className="box hamburger-icon" onClick={toggleSidebar}>
        <svg id="i2" className="icon" viewBox="0 0 100 100">
          <path id="top-line-2" d="M30,37 L70,37 Z"></path>
          <path id="middle-line-2" d="M30,50 L70,50 Z"></path>
          <path id="bottom-line-2" d="M30,63 L70,63 Z"></path>
        </svg>
      </div>
      <div className={`sidebar-overlay ${sidebarOpen ? 'visible' : ''}`} onClick={closeSidebar}></div>
    </header>
  );
};

export default Header;
