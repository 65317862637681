import React, { useState, useEffect } from 'react';
import '../styles/TechStackPage.css';
import nodejsLogo from '../assets/nodejs.png';
import phpLogo from '../assets/php.png';
import mysqlLogo from '../assets/mysql.png';
import javaLogo from '../assets/java.png';
import netcoreLogo from '../assets/netcore.png';
import pythonLogo from '../assets/python.png';
import railsLogo from '../assets/rails.png';
import goLogo from '../assets/Go.png';
import mongodbLogo from '../assets/mongodb.png';
import css from '../assets/css-3.png';
import html from '../assets/html.png';
import angular from '../assets/angular.png';
import prom from '../assets/prometheus.png';
import grafana from '../assets/grafana.png';
import firebase from '../assets/firebase.png';
import react from '../assets/react.png';
import flutter from '../assets/flutter.png';
import wordpress from '../assets/wordpress-logo.png';
import vitejs from '../assets/Vitejs.png';
import boostrap from '../assets/Bootstrap.png';
import tailwind from '../assets/Tailwind CSS.png';
import docker from '../assets/docker-icon.png';
import awsLogo from '../assets/aws.png';
import azureLogo from '../assets/azure.png';
import gcpLogo from '../assets/gcp.png';
import tensorflowLogo from '../assets/tensorflow.png';
import pytorchLogo from '../assets/pytorch.png';
import opencvLogo from '../assets/opencv.png';
import git from '../assets/git.png';
import github from '../assets/github.png';

const sections = [
  {
    name: "Frontend",
    logos: [
      { src: html, alt: 'HTML Logo' },
      { src: css, alt: 'CSS Logo' },
      { src: angular, alt: 'Angular Logo' },
      { src: react , alt: 'React Logo'},
      { src: flutter, alt: 'Flutter Logo' },
      { src: vitejs, alt: 'Vitejs Logo'},
      { src: boostrap, alt: 'Bootstrap Logo'},
      { src: tailwind, alt: 'Tailwind CSS Logo'}
    ]
  },
  {
    name: "Backend",
    logos: [
      { src: nodejsLogo, alt: 'Node.js Logo' },
      { src: phpLogo, alt: 'PHP Logo' },
      { src: javaLogo, alt: 'Java Logo' },
      { src: netcoreLogo, alt: '.NET Core Logo' },
      { src: pythonLogo, alt: 'Python Logo' },
      { src: railsLogo, alt: 'Rails Logo' },
      { src: goLogo, alt: 'Go Logo' },
    ]
  },
  {
    name: "Databases",
    logos: [
      { src: mysqlLogo, alt: 'MySQL Logo' },
      { src: mongodbLogo, alt: 'MongoDB Logo' },
      { src: firebase, alt: 'Firebase Logo'},
    ]
  },
  {
    name: "CMS",
    logos: [
      { src: wordpress, alt: 'WordPress Logo' },
    ]
  },
  {
    name: "DevOps",
    logos: [
      { src: prom, alt: 'Prometheus Logo' },
      { src: grafana, alt: 'Grafana Logo' },
      { src: docker, alt: 'Docker Logo' },
      { src: git, alt: 'Git Logo'},
      { src: github, alt:'GitHub Logo'}
    ]
  },
  {
    name: "Cloud Services",
    logos: [
      { src: awsLogo, alt: 'AWS Logo' },
      { src: azureLogo, alt: 'Azure Logo' },
      { src: gcpLogo, alt: 'Google Cloud Platform Logo' }
    ]
  },
  {
    name: "Artificial Intelligence",
    logos: [
      { src: tensorflowLogo, alt: 'TensorFlow Logo' },
       { src: pytorchLogo, alt: 'PyTorch Logo' },
      { src: opencvLogo, alt: 'OpenCV Logo' }
    ]
  }
];

const getLogoRows = (logos) => {
  const rows = [];
  if (logos.length === 8) {
    rows.push(logos.slice(0, 4));
    rows.push(logos.slice(4));
  } else if (logos.length === 7) {
    rows.push(logos.slice(0, 4));
    rows.push(logos.slice(4));
  } else if (logos.length === 6) {
    rows.push(logos.slice(0, 3));
    rows.push(logos.slice(3));
  } else {
    rows.push(logos);
  }
  return rows;
};

const TechStackPage = () => {
  const [currentSection, setCurrentSection] = useState(0);

  useEffect(() => {
    const rows = document.querySelectorAll('.tech-logos-row');
    rows.forEach(row => {
      const logoItems = row.querySelectorAll('.tech-logo-item');
      logoItems.forEach((logo, index) => {
        logo.style.animationDelay = `${index * 0.1}s`; // Stagger the animations
        logo.classList.remove('bounceInLeft'); // Remove the class to reset animation
        void logo.offsetWidth; // Trigger reflow to allow re-adding animation class
        logo.classList.add('bounceInLeft');
      });
    });
  }, [currentSection]);

  return (
    <div id="tech-stack" className="tech-stack-container">
      <h2>Our Tech Stack</h2>
      <div className="tech-stack-tabs">
        {sections.map((section, index) => (
          <button
            key={index}
            className={`tech-tab ${currentSection === index ? 'active' : ''}`}
            onClick={() => setCurrentSection(index)}
          >
            {section.name}
          </button>
        ))}
      </div>
      <div className="tech-stack-content">
        <div className="tech-logos-wrapper">
          {getLogoRows(sections[currentSection].logos).map((row, rowIndex) => (
            <div key={rowIndex} className={`tech-logos-row row-${rowIndex + 1}`}>
              {row.map((logo, index) => (
                <div key={index} className="tech-logo-item">
                  <img src={logo.src} alt={logo.alt} className="tech-logo" loading="lazy" />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechStackPage;
