import React from 'react';
import { PiStarFourFill } from 'react-icons/pi';
import '../styles/Wording.css';

const Wording = () => {
  return (
    <div className="wording-main-container">
      <div className="animated-background"></div>
      <div className="star-icon small-star top-left">
        <PiStarFourFill className="star-icon pulse" />
      </div>
      <div className="star-icon small-star top-right">
        <PiStarFourFill className="star-icon pulse" />
      </div>
      <div className="star-icon small-star bottom-left">
        <PiStarFourFill className="star-icon pulse" />
      </div>
      <div className="content-container">
        <h1>Smart Solutions<br></br>for a Connected World.</h1>
      </div>
    </div>
  );
};

export default Wording;
