import React from 'react';
import Header from './components/Header';
import MainSection from './components/MainSection';
import EasyProcess from './components/EasyProcess';
import Services from './components/Services';
import TechStackPage from './components/TechStackPage';
import Wording from './components/Wording'; 
import Contact from './components/Contact';
import Footer from './components/Footer';
import './index.css';
import StarGlitter from './components/starGlitter';

const App = () => (
  <div>
    <Header />
    <MainSection />
    <EasyProcess />
    <Services />
    <TechStackPage />
    <Wording />
    <Contact/>
    <StarGlitter/>
    <Footer />
  </div>
);

export default App;
