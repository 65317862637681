import React, { useEffect } from 'react';
import { FaInstagram, FaWhatsapp, FaFacebook, FaLinkedin } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer = () => {

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector('.footer-container');
      const footerTop = footer.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (footerTop < windowHeight - 100) {
        footer.classList.add('aligned');
      } else {
        footer.classList.remove('aligned');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section about-section">
          <p>At nebsia, we specialize in creating cutting-edge apps, web services, AI solutions, and cloud services tailored to meet the unique needs of our clients. Our mission is to empower businesses with innovative technology solutions.</p>
        </div>
        <div className="footer-section links-section">
          <h3>Links</h3>
          <nav>
            <a href="#about" onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth' })}>About Us</a>
            <a href="#services" onClick={() => document.getElementById('services').scrollIntoView({ behavior: 'smooth' })}>Services</a>
            <a href="#blog" onClick={() => document.getElementById('tech-stack').scrollIntoView({ behavior: 'smooth' })}>tech stack</a>
            <a href="#easy-process" onClick={() => document.getElementById('easy-process').scrollIntoView({ behavior: 'smooth' })}>Use Cases</a>

          </nav>
        </div>
        <div className="footer-section contact-section">
          <h3>Contact Us</h3>
          <p>Interested in working with nebsia? We're here to help you leverage the power of technology to achieve your business goals. Reach out to us today.</p>
          <div className="social-icons">
            <a href="https://wa.me/+918300076220" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
            <a href="https://www.instagram.com/nebsia_official?igsh=dGtmMXE0Mmh5ZmQ1" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://www.facebook.com/profile.php?id=61561860430631&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://www.linkedin.com/company/nebsia/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 nebsia. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
